.grid-space {
  height: 200px;

  @include media-breakpoint-up(sm) {
    height: 250px;
  }

  &.grid-space-small {
    height: 75px;

    @include media-breakpoint-up(sm) {
      height: 100px;
    }
  }
}

.section-spacing {
  padding-top: 162px;
  padding-bottom: 150px;
}

.section-spacing-small {
  padding-top: 62px;
  padding-bottom: 160px;
}

.quotes-section {
  padding-bottom: 68px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 93px;
  }
}
.quotes-spacing {
  margin-bottom: 35px;
}

.about-section {
  padding-bottom: 66px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 185px;
  }

  p {
    margin-bottom: 28px;
  }
}

.info-banner {
  padding-top: 136px;
  padding-bottom: 160px;
  p {
    padding: 34px 0;
  }
}
