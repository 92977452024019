.filter-links {
  color: #696b70;
  &,
  & a {
    font-size: 15px;
    line-height: 22px;

    @include media-breakpoint-up(sm) {
      font-size: 22px;
      line-height: 32px;
    }
  }
}

.filter-link {
  padding-left: 30px;
  flex-shrink: 0;
}

.filter-link-last {
  padding-right: 52px;
  @include media-breakpoint-up(sm) {
    padding-right: 0;
  }
}

.filter-links-links {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.filter-links-title {
  margin-right: 5px;
}

p.filter-links a {
  color: #838589;

  &:hover,
  &.active {
    color: #ff7600;
  }

  &.link-hover {
    &:before,
    &:after {
      bottom: -3px;
    }
  }
}

.filter-links-spaced {
  margin-top: 37px;
  margin-bottom: 71px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 90px;
  }
}
