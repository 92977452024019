.references-slider {
  .swiper-pagination {
    position: relative;
    height: 1px !important;
    bottom: 1px !important;
    left: 0 !important;
    background: #f6f6f6;
    z-index: 3;
    .swiper-pagination-progressbar-fill {
      background: $color-orange;
    }
  }

  .swiper-navigation {
    position: absolute;
    bottom: 326px;
    z-index: 10;
    @include media-breakpoint-up(sm) {
      bottom: 189px;
    }
    @include media-breakpoint-up(lg) {
      bottom: 128px;
    }
  }

  .swiper-slide-active {
    z-index: 2;
  }

  .swiper-slide {
    opacity: 0 !important;
    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }
}

.arrow-button-next {
  cursor: pointer;
  #arrow-right {
    transition: 0.3s;
  }
  &:hover {
    #arrow-right {
      stroke: $color-orange;
    }
  }
}
.arrow-button-prev {
  cursor: pointer;
  #arrow-left {
    transition: 0.3s;
  }
  &:hover {
    #arrow-left {
      stroke: $color-orange;
    }
  }

  margin-right: 6px;
  @include media-breakpoint-up(sm) {
    margin-right: 9px;
  }
}

.quotes-slider,
.reference-quote {
  padding-top: 141px;
  padding-bottom: 141px;
  .swiper-container {
    overflow: unset;
    overflow-y: visible !important;
  }
  .swiper-slide-active {
    z-index: 2;
  }
  .swiper-slide {
    height: auto;
    display: flex;
    opacity: 0 !important;
    &.swiper-slide-active {
      opacity: 1 !important;
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .swiper-pagination {
    position: relative;
    height: 1px !important;
    top: 74px !important;
    left: 0 !important;
    background: #e7e8ea;
    z-index: 3;

    @include media-breakpoint-up(sm) {
      top: 64px !important;
    }
    @include media-breakpoint-up(lg) {
      top: 80px !important;
    }
    .swiper-pagination-progressbar-fill {
      background: $color-orange;
    }
  }
  .quote-refs {
    align-items: flex-end;
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }
  }

  .quote-author {
    font-size: 15px;
    margin-top: 37px;
    @include media-breakpoint-up(sm) {
      font-size: 22px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 79px;
    }
  }
  .quote-reference {
    font-size: 14px;
    color: #838589;
    @include media-breakpoint-up(sm) {
      font-size: 17px;
    }
  }
  h2 {
    font-size: 28px;
    line-height: 33px;
    @include media-breakpoint-up(sm) {
      font-size: 44px;
      line-height: 47px;
    }
  }
  .quote-swiper-click-catch {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
  }
  .quote-long {
    h2 {
      font-size: 24px;
      line-height: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 31px;
        line-height: 40px;
      }
    }
  }
}
