.navbar-logo {
  font-size: 19px;
  color: $color-orange;
  font-family: "MonumentExtended";
  font-weight: 400;

  letter-spacing: 2.03px;
  line-height: 61px;
  transition: 0.3s;
  width: 193px;
  display: inline-block;
  &:hover {
    color: $color-black;
    text-decoration: none;
  }
  @include media-breakpoint-up(sm) {
    font-size: 25px;
  }
}

.logo-subtitle {
  display: none;
  @include media-breakpoint-up(lg) {
    display: inline-block;
    color: #838589;
    font-size: 14px;
    font-family: "MaisonNeue";
    line-height: 19px;
  }
}
