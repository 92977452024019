.description-text {
  padding-top: 24px;

  @include media-breakpoint-up(sm) {
    padding-top: 11px;
  }
}

.description-spacing {
  letter-spacing: unset;
  @include media-breakpoint-up(xl) {
    letter-spacing: 1.25px;
  }
}
