.icon-link-icon {
  position: absolute;
  top: 0;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #838589;
  font-size: 19px;
  line-height: 32px;
}

.icon-link a {
  color: #151b22;
  padding-top: 16px;
  padding-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .icon-link-external-icon {
      transition-duration: 0.3s;
      transform: rotate(0);
    }

    .icon-link-internal-icon {
      transition: 0.5s;
      transform: translate(-18px);
    }
  }
  .icon-link-external-icon {
    transition-duration: 0.8s;
    transform: rotate(-45deg);
  }

  .icon-link-internal-icon {
    transition-duration: 0.8s;
    position: relative;
  }
}

.icon-link-after-text {
  color: #838589;
  font-size: 15px;
  line-height: 36px;
  padding-top: 7px;
  padding-bottom: 7px;

  @include media-breakpoint-up(sm) {
    font-size: 17px;
  }
}
