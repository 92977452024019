.text-box {
  svg {
    margin-top: 10px;
  }

  p {
    font-size: 15px;
    line-height: 22px;

    @include media-breakpoint-up(sm) {
      font-size: 17px;
      line-height: 25px;
    }
  }

  h3 {
    font-size: 18px;

    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }

    margin-top: 20px;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }

  &.text-box-high-lines {
    h3 {
      margin-bottom: 15px;
    }

    p {
      line-height: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
        line-height: 42px;
      }
    }
  }
}
