footer {
  padding-top: 55px;
}

.footer-content-wrapper {
  padding: 41px 25px 75.5px;
  border-top: 1px solid rgba(91, 99, 108, 0.1);
  @include media-breakpoint-up(sm) {
    display: flex;
  }
  .footer-section a,
  .footer-section div {
    color: $color-black;
    font-size: 17px;
    line-height: 34px;
  }
  .footer-section .contact-link {
    color: $color-orange;
  }
}

.footer-section {
  .footer-section-heading {
    position: relative;
    border-bottom: 1px solid rgba(91, 99, 108, 0.1);
    color: #838589;
    font-size: 15px;
    line-height: 20px;

    padding-bottom: 14px;
    padding-top: 13px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      display: block;
      height: 1px;
      width: 18px;
      background-color: $color-orange;
    }
  }
  .footer-section-links {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    margin-bottom: 42px;
  }
}

.footer-section-logo {
  display: none;
  .navbar-logo {
    width: 100%;
    border-bottom: 1px solid rgba(91, 99, 108, 0.1);
    margin-right: 272px;
    @include media-breakpoint-up(xl) {
      margin-right: 282.29px;
    }
  }
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.footer-bottom {
  border-top: 1px solid rgba(91, 99, 108, 0.1);
  padding: 42.5px 0 63px;
  .container {
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
    .follow-us {
      font-size: 15px;
      color: $color-orange;
      margin-right: 5px;
    }
  }
  .copy {
    display: flex;
    justify-content: center;
    .link-list {
      text-align: center;
      max-width: 298px;
      color: #5b636c;
      font-size: 15px;
      @include media-breakpoint-up(sm) {
        max-width: none;
      }
      a {
        color: #5b636c;
        font-size: 15px;
      }
    }
  }
}

.social-link {
  margin: 0 4.5px;
  width: 50px;
  height: 50px;
  border: 1px solid $color-orange;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  svg #Artboard {
    transition: 0.4s;
    fill: $color-orange;
  }
  &:hover {
    border-color: $color-black;
    svg #Artboard {
      fill: $color-black;
    }
  }
}
