.lead {
  padding-top: 46px;
  padding-bottom: 0;

  @include media-breakpoint-up(sm) {
    padding-top: 143px;
    padding-bottom: 70px;
  }
}

p {
  color: #696b70;
}

.lead-title {
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 35px;
  }
}

.appear-section {
  .appear-wrapper-h1 {
    overflow: hidden;
    position: relative;
    height: 38px;
    @include media-breakpoint-up(sm) {
      height: 60px;
    }
    @include media-breakpoint-up(lg) {
      height: 72px;
    }
  }
  .appear-content-h1 {
    transition-duration: 1.3s;
    transition-delay: 1s;
    transition-timing-function: cubic-bezier(0.375, 0.725, 0, 1);
    position: relative;
    top: 36px;
    @include media-breakpoint-up(sm) {
      top: 60px;
    }
    @include media-breakpoint-up(lg) {
      top: 72px;
    }
  }

  &.appear-section-appeared .appear-content-h1 {
    top: 0;
  }
}

.lead-text {
  @include media-breakpoint-up(lg) {
    margin-left: 188px;
    margin-right: 88px;
  }
}
.lead-links {
  margin-top: 40px;
  display: flex;
  flex-flow: column;

  @include media-breakpoint-up(sm) {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }

  .lead-link-first {
    margin-right: 28px;
  }

  .lead-scroll {
    margin-top: 55px;
    z-index: 10;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
}

.after-lead {
  margin-top: -24px;
  position: relative;
  z-index: 1;

  @include media-breakpoint-up(sm) {
    margin-top: 0px;
  }
}
