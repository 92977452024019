.box-list-items {
  padding-top: 40px;

  @include media-breakpoint-up(sm) {
    padding-top: 60px;
  }
}

p {
  color: #838589;
}

.box-list-items {
  .col-lg-4,
  .col-sm-6 {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }

    &:nth-last-of-type(2),
    &:nth-last-of-type(3) {
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
}
