.timeline {
  margin-top: 50px;

  @include media-breakpoint-up(lg) {
    margin-top: 80px;
  }
}

.timeline-item {
  padding-bottom: 33px;
  padding-top: 33px;
  display: flex;
  flex-flow: column;

  @include media-breakpoint-up(sm) {
    flex-flow: row;
  }

  p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      font-size: 17px;
    }
  }

  h3 {
    font-size: 18px;
    margin: 12px 0;

    @include media-breakpoint-up(sm) {
      font-size: 28px;
    }
  }

  .timeline-item-step {
    color: #ff7600;
    font-size: 17px;
    line-height: 32px;

    @include media-breakpoint-up(sm) {
      padding-top: 17px;
      width: 65px;
    }
  }

  .timeline-item-texts {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
    }
  }

  .timeline-item-description {
    @include media-breakpoint-up(lg) {
      width: 485px;
    }
  }
}
