.responsive-image {
  position: relative;
  img {
    display: none;

    @include media-breakpoint-up(sm) {
      max-width: 100%;
      display: block;
    }
  }

  height: 292px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(sm) {
    min-height: 384px;
    height: auto;
    background: none;
  }
}

.responsive-div-image-wrapper {
  @media screen and (max-width: 375px) {
    height: 292px;
  }
  height: 393px;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    height: 442px;
  }
  @include media-breakpoint-up(md) {
    height: 740px;
  }
}

.responsive-div-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;
  &.background-right {
    background-position: right;
  }
}

.about-image-left-wrapper {
  margin-right: 1%;
  flex-basis: 61%;
}

.about-image-right-wrapper {
  flex-basis: 38%;
}

.reference-image-separator {
  width: 3px;
  background-color: $color-grey-soft-1;
}
