h1 {
  color: #151b22;
  font-size: 32px;
  line-height: 32.88px;

  @include media-breakpoint-up(sm) {
    font-size: 56px;
    line-height: 65px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 64px;
    line-height: 70px;
    border-right: 1px solid $color-orange;
  }

  &.no-line {
    border-right: none;
    margin-right: 0;
  }
}

h2 {
  color: #151b22;
  font-size: 24px;
  line-height: 28px;

  @include media-breakpoint-up(sm) {
    font-size: 44px;
    line-height: 47px;
  }
}

h3 {
  font-size: 23px;
  line-height: 36px;

  @include media-breakpoint-up(sm) {
    font-size: 24px;
    line-height: 36px;
  }
}

p {
  color: #838589;
  font-size: 15px;
  line-height: 22px;

  @include media-breakpoint-up(sm) {
    font-size: 25px;
    line-height: 38px;
  }
}

a {
  font-size: 15px;
  line-height: 22px;
  color: $color-orange;
  text-decoration: none;

  &:hover {
    color: $color-orange;
  }

  @include media-breakpoint-up(sm) {
    font-size: 22px;
    line-height: 32px;
  }
  &.link-hover {
    transition: 1s;
    transition: all 0.2s ease-in-out;
    position: relative;
    &:after {
      content: "";
      left: 0;
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      margin: 5px 0 0;
      transition: all 0.2s ease-in-out;
      transition-duration: 0.5625s;
      opacity: 0;
      background-color: $color-black;
    }
    &:hover,
    &.active {
      cursor: pointer;
      text-decoration: none;

      &:before,
      &:after {
        transition-duration: 0.375s;
        width: 100%;
        opacity: 1;
      }
    }
    &.hover-orange {
      &:before,
      &:after {
        background-color: $color-orange;
      }
    }
  }
}

.text-small {
  font-size: 14px !important;
  line-height: 22px !important;

  @include media-breakpoint-up(sm) {
    font-size: 17px !important;
    line-height: 25px !important;
  }
}
