@font-face {
  font-family: "MaisonNeue";
  src: url("../fonts/MaisonNeue/MaisonNeue-Black.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "MaisonNeue";
  src: url("../fonts/MaisonNeue/MaisonNeue-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "MaisonNeue";
  src: url("../fonts/MaisonNeue/MaisonNeue-Bold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "MaisonNeue";
  src: url("../fonts/MaisonNeue/MaisonNeue-Book.otf");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: "MonumentExtended";
  src: url("../fonts/MonumentExtended/MonumentExtended-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MonumentExtended";
  src: url("../fonts/MonumentExtended/MonumentExtended-Ultrabold.otf");
  font-weight: 700;
  font-style: normal;
}
