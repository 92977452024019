.reference-content-wrapper {
  margin: 0;
  @include media-breakpoint-up(lg) {
    margin-left: 183px;
  }
}
.reference-data {
  .title-spacing {
    padding-top: 126px;
    padding-bottom: 8px;
    padding-left: 0;
    @include media-breakpoint-up(sm) {
      padding-top: 64px;
      padding-bottom: 28px;
      padding-left: 118px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }
  .reference-properties {
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-up(sm) {
      margin-top: 28px;
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
      justify-content: space-around;
      margin: 0;
    }
    flex-basis: 82%;
  }
  .property-item-wrapper {
    overflow: hidden;
    display: inline-block;
    position: relative;
    height: 22px;
    margin: 7.5px 0;
    @include media-breakpoint-up(sm) {
      height: 32.22px;
      margin: 0;
    }
    &:hover .property-item {
      top: -22px;
      @include media-breakpoint-up(sm) {
        top: -32.22px;
      }
    }
    .property-item {
      transition: 0.25s;
      position: relative;
      top: 0;
      font-size: 15px;
      color: #838589;
      letter-spacing: -0.3px;
      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }
      div {
        text-align: left;
        @include media-breakpoint-up(sm) {
          text-align: center;
        }
        white-space: nowrap;
      }
    }
  }
}
.single-reference {
  .title-spacing {
    padding-top: 48px;
  }

  .references-divider {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
