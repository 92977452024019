.bg-grid {
  border-left: 1px #f2f3f6 solid;
  border-right: 1px #f2f3f6 solid;
  position: relative;

  &::before {
    display: block;
    position: absolute;
    width: 1px;
    left: calc(50% - 1px);
    height: 100%;
    background-color: #f2f3f6;
    content: "";
    z-index: -1;
  }
}
