.text-image {
  display: flex;
  flex-flow: column;
  @include media-breakpoint-up(lg) {
    flex-flow: row;
  }

  .text-image-text-wrapper {
    overflow: hidden;
    order: 2;

    .text-image-text {
      padding-top: 178px;
      height: 780px;
      background-color: #f6f6f6;
      &.no-bg {
        @include media-breakpoint-up(lg) {
          background-color: unset;
        }
      }
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
      @include media-breakpoint-up(xl) {
        width: 100%;
      }
    }

    &:last-child .text-image-text {
      @include media-breakpoint-up(lg) {
        padding-left: 125px;
        padding-right: calc(100% - 555px);
      }

      @include media-breakpoint-up(xl) {
        padding-right: calc(100% - 648px);
      }

      @include media-breakpoint-up(xxl) {
        padding-right: calc(100% - 668px);
      }
    }

    &:first-child .text-image-text {
      @include media-breakpoint-up(lg) {
        padding-right: 125px;
        padding-left: calc(100% - 555px);
      }

      @include media-breakpoint-up(xl) {
        padding-left: calc(100% - 648px);
      }

      @include media-breakpoint-up(xxl) {
        padding-left: calc(100% - 668px);
      }
    }
    @include media-breakpoint-up(lg) {
      width: calc(50% + 125px);
      order: unset;
    }
    @include media-breakpoint-up(xl) {
      width: 50%;
    }
  }
  .text-image-text-container {
    max-width: 600px;
    padding-left: 26px;
    padding-right: 25px;
    margin: auto;

    @include media-breakpoint-up(sm) {
      max-width: 625px;
      padding-left: unset;
      padding-right: unset;
    }
    @include media-breakpoint-up(md) {
      max-width: 719px;
    }
    @include media-breakpoint-up(lg) {
      max-width: unset;
      margin: unset;
    }
  }
  .text-image-image-wrapper {
    order: 1;
    @include media-breakpoint-up(lg) {
      order: unset;
      flex-grow: 1;
    }
    overflow: hidden;
    height: 292px;
    @include media-breakpoint-up(sm) {
      height: 442px;
    }

    @include media-breakpoint-up(lg) {
      height: 740px;
    }
  }
  .text-image-image {
    order: 1;
    @include media-breakpoint-up(lg) {
      order: unset;
      flex-grow: 1;
    }

    height: 292px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(sm) {
      height: 442px;
    }

    @include media-breakpoint-up(lg) {
      height: 740px;
    }
  }

  p {
    @include media-breakpoint-up(sm) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  h2 {
    margin-bottom: 25px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 30px;
    }
  }

  h3 {
    @include media-breakpoint-up(sm) {
      margin-top: 40px;
      margin-bottom: 18px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 15px;
      line-height: 22px;
    }
  }
}

.text-image-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  .container,
  .bg-grid {
    height: 100%;
  }
}
