@import "./styles/colors";
@import "./styles/bootstrap";

$hamburger-layer-width: 25px;
$hamburger-layer-height: 1px;
$hamburger-padding-x: 11px;
$hamburger-padding-y: 13px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $color-black;

@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";

@import "./styles/swiper";

@import "swiper/swiper.scss";
@import "swiper/components/navigation/navigation.scss";
@import "swiper/components/pagination/pagination.scss";

@import "./styles/fonts";
@import "./styles/text";
@import "./styles/navbar";
@import "./styles/toggle-navbar-button";
@import "./styles/bg-grid";
@import "./styles/logo";
@import "./styles/lead";
@import "./styles/responsive-image";
@import "./styles/spacing";
@import "./styles/footer";
@import "./styles/description";
@import "./styles/links";
@import "./styles/box-list";
@import "./styles/text-box";
@import "./styles/timeline";
@import "./styles/reference-data";
@import "./styles/filter";
@import "./styles/team";
@import "./styles/text-image";
@import "./styles/appear-animation";
@import "./styles/reference-detail";

body {
  font-family: "MaisonNeue";
  font-weight: 700;
}
.container-fluid {
  max-width: 1480px;
}

.container {
  padding-left: 26px;
  padding-right: 25px;

  position: relative;

  @include media-breakpoint-up(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-weight: 350;
}
