header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  border-bottom: #dedede solid 1px;
  background-color: white;
}

.after-navbar {
  height: 80px;
  @include media-breakpoint-up(sm) {
    height: 136px;
  }
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 79px;
  transition: 0.5s;
  @include media-breakpoint-up(sm) {
    height: 136px;
    margin-top: 0;

    &.navbar-compact {
      height: 79px;
    }
  }
}

.navbar-collapse {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 80px;
  width: 100%;
  opacity: 1;
  height: calc(100vh - 80px);
  transition-duration: 0.3s;

  background-color: white;
  @include media-breakpoint-up(sm) {
    display: flex;
    height: 100%;
    width: auto;
    flex-grow: 1;
    flex-basis: auto;
    position: static;
  }
}

.navbar-nav {
  padding: 0 26px;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  flex-direction: column;
  flex-grow: 1;
  @include media-breakpoint-up(sm) {
    display: flex;
    list-style: none;
    flex-direction: row;
    margin-bottom: 0;
    margin-left: auto;
    width: auto;
  }

  @include media-breakpoint-up(sm) {
    padding: 0;
  }
}

.nav-item.phone-link {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
    margin-left: 68px;
  }
}
.nav-item {
  margin: 0;
  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: stretch;
    margin-left: 26px;

    a {
      display: flex;
      align-items: stretch;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-left: 46px;
  }
}
.nav-link {
  white-space: nowrap;
  color: #171717;
  font-size: 32px;
  line-height: 76px;
  &:hover {
    text-decoration: none;
  }
  .nav-hover:hover {
    color: #838589;
  }
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}
.navbar-collapse.collapsed {
  opacity: 0;
  height: 0;
  transition-delay: 0.3s;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    width: auto;
    opacity: 1;
    height: 100%;
  }
}

.close-menu-wrapper {
  padding-bottom: 95px;
  margin: auto;
  text-align: center;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.close-menu-button {
  white-space: nowrap;
  color: $color-orange;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.2px;
  padding: 0;
  border: 0;
  background-color: transparent;
  &:hover {
    color: $color-orange;
  }
  &:focus {
    outline: none;
  }
}

@include media-breakpoint-up(sm) {
  .nav-link {
    .nav-hover {
      display: flex;
      align-items: center;
      transition: 1s;
      margin: 0 auto;
      padding: 0;
      transition: all 0.2s ease-in-out;
      position: relative;
      &:before,
      &:after {
        content: "";
        left: 0;
        position: absolute;
        bottom: 0;
        width: 0px;
        height: 5px;
        margin: 5px 0 0;
        transition: all 0.2s ease-in-out;
        transition-duration: 0.5625s;
        opacity: 0;
        background-color: $color-orange;
      }
    }
    &:hover {
      cursor: pointer;
      .nav-hover {
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
          transition-duration: 0.375s;
        }
      }
    }
  }
}

.reference-navbar-logo {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.reference-navbar-close-link {
  font-size: 18px;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
