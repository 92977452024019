$xs-breakpoint: 375px;
$sm-breakpoint: 768px;
$md-breakpoint: 860px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1440px;
$xxl-breakpoint: 1680px;

$grid-gutter-width: 0;
$grid-breakpoints: (
  xs: 0,
  sm: $sm-breakpoint,
  md: $md-breakpoint,
  lg: $lg-breakpoint,
  xl: $xl-breakpoint,
  xxl: $xxl-breakpoint,
);
$container-max-widths: (
  xs: 600px,
  sm: 625px,
  md: 719px,
  lg: 1110px,
  xl: 1297px,
  xxl: 1338px,
);

$theme-colors: (
  primary: $color-orange,
);

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/utilities";

.row {
  @include media-breakpoint-up(sm) {
    margin-right: -18px;
    margin-left: -18px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: -36px;
    margin-left: -36px;
  }

  @include media-breakpoint-up(xl) {
    margin-right: -46px;
    margin-left: -46px;
  }
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  @include media-breakpoint-up(sm) {
    padding-right: 18px;
    padding-left: 18px;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 36px;
    padding-left: 36px;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 46px;
    padding-left: 46px;
  }
}
