.team {
  padding-top: 112px;

  .team-member {
    padding-bottom: 40px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 30px;
    }

    .team-member-image {
      margin-left: -27px;
      margin-right: -26px;

      @include media-breakpoint-up(sm) {
        margin-left: 0;
        margin-right: 0;
      }

      margin-bottom: 25px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    h3 {
      font-size: 18px;
      margin-bottom: 0;

      @include media-breakpoint-up(sm) {
        font-size: 30px;
        margin-bottom: 5px;
      }
    }

    p {
      font-size: 15px;
      line-height: 22px;

      @include media-breakpoint-up(sm) {
        font-size: 17px;
        line-height: 28px;
      }
    }
  }
}
