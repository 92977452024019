.navbar-toggler {
  display: block;
  cursor: pointer;
  border: 1px solid $color-black;
  border-radius: 25px;
  width: 49px;
  height: 49px;
  margin: 16px 0;
  @include media-breakpoint-up(sm) {
    display: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
}
