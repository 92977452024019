$color-orange: #ff7600;
$color-black: #151b22;
$color-grey-dark: #696b70;
$color-grey-soft-1: #d1d5dc;
$color-grey-soft-2: #f2f3f6;
$color-grey-soft-3: #f6f6f6;

.bg-gray {
  background-color: $color-grey-soft-3;
}
