.references-slider {
  .appear-wrapper-h2 {
    overflow: hidden;
    position: relative;
    height: 36px;
    @include media-breakpoint-up(sm) {
      height: 54.6px;
    }

    .appear-content-h2 {
      transition: 0.35s;
      position: relative;
      top: 36px;
      @include media-breakpoint-up(sm) {
        top: 56.6px;
      }
    }
  }

  .appear-content-property {
    transition: 0.35s;
    position: relative;
    top: 32px;
  }

  .swiper-slide-active {
    .appear-content-h2 {
      top: 0;
    }
    .appear-content-property {
      top: 0;
    }
  }
}

.reference-data {
  .appear-wrapper-property {
    overflow: hidden;
    position: relative;
    height: 32px;
  }
}

.quotes-slider {
  .swiper-slide {
    .appear-wrapper-h2 {
      overflow: hidden;
      position: relative;
      height: 36px;
      @include media-breakpoint-up(sm) {
        height: 52.6px;
      }

      .appear-content-h2 {
        transition: 0.35s;
        position: relative;
        top: 36px;
        @include media-breakpoint-up(sm) {
          top: 56.6px;
        }
      }
    }
    .appear-wrapper-author {
      overflow: hidden;
      position: relative;
      height: 60px;
      @include media-breakpoint-up(sm) {
        height: 70px;
      }
      @include media-breakpoint-up(lg) {
        height: 112px;
      }

      .appear-content-author {
        transition: 0.35s;
        position: relative;
        top: 60px;
        @include media-breakpoint-up(sm) {
          top: 70px;
        }
        @include media-breakpoint-up(lg) {
          top: 112px;
        }
      }
    }
    .appear-wrapper-reference {
      overflow: hidden;
      position: relative;
      height: 20px;
      .appear-content-reference {
        transition: 0.35s;
        position: relative;
        top: 60px;
        @include media-breakpoint-up(sm) {
          top: 70px;
        }
        @include media-breakpoint-up(lg) {
          top: 112px;
        }
      }
    }

    &.swiper-slide-active {
      .appear-content-h2,
      .appear-content-author,
      .appear-content-reference {
        top: 0;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .navbar-collapse {
    .nav-item {
      overflow: hidden;
      position: relative;
      height: 76px;
    }
    .nav-link {
      position: relative;
      top: 0;
      transition-delay: 0.3s;
      transition-duration: 0.3s;
    }
  }
  .navbar-collapse.collapsed {
    .nav-link {
      transition-delay: 0s;

      top: 76px;
      transition-duration: 0.3s;
    }
  }
}

.appear-images {
  .responsive-image {
    opacity: 0;
    transition: all 200ms ease 0s;
  }
  &.swiper-slide-active {
    .responsive-image {
      opacity: 1;
    }
  }
}
